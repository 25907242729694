@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/ProximaNova-Regular.ttf');
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/ProximaNova-Bold.ttf');
  font-weight: bold;
}

body {
  background: #f5f8f9;
}

.warning {
 background-color: rgba(255, 0, 0, 0.07);
}

.warning-text {
  color: #ff0000;
  font-style: italic;
  font-size: 12px;
}
